<template>
    <div class="d-flex align-items-center justify-content-between py-10 container" >
        <div class="col-md-12 p-0">
            <div class="card card-custom">
                <div class="card-header">
                    <div class="card-title">
                        <span class="card-icon">
                            <i class="fas fa-clipboard-list text-aqua"></i>
                        </span>
                        <h1 class="card-label text-uppercase">TERMS OF USE</h1>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 py-5 px-7">
                          <p>
                            {{ getCompanyInfo.name }} only provides a technology platform that connects intending travelers with bus operators. It doesn't operate any bus or offer the service of transportation to the User.
                            {{ getCompanyInfo.name }} also doesn't act as an agent of any bus operator in the process of providing the above-mentioned technology platform services.
                          </p>
                          <p>
                            The bus ticket booking voucher which {{ getCompanyInfo.name }} issues to a User is solely based on the information provided or updated by the bus operator regarding the seat availability.
                          </p>
                          <p>
                            The amenities, services, routes, fares, schedule, bus type, seat availability and any other details pertaining to the bus service are provided by the respective bus operator and {{ getCompanyInfo.name }} has no control over such information provided by the bus operator.
                          </p>
                          <h3>Limitation of Liability of {{ getCompanyInfo.name }}</h3>
                          <p>In its role as a technology platform to facilitate transactions between the bus operators and the Users, {{ getCompanyInfo.name }} shall not be responsible for the operations of the bus operator including, but not limited to the following:</p>
                          <ul>
                            <li>Timely departure or arrival of the bus;</li>
                            <li>The conduct of bus operator's employees, representatives or agents;</li>
                            <li>The condition of the bus, seats etc. not being up to the customer's expectation or as per the description provided by the bus operator;</li>
                            <li>Cancellation of the trip due to any reasons;</li>
                            <li>Loss or damage of the baggage of the customer;</li>
                            <li>The bus operator changing a customer's seat for any reason whatsoever;</li>
                            <li>Bus operator informing a wrong boarding point for the issuance of the booking confirmation voucher, or changing such boarding point eventually with or without any notification to {{ getCompanyInfo.name }} or the User;</li>
                            <li>Bus operator using a separate pick-up vehicle to transport the User from the designated boarding point to the actual place of departure of the bus.</li>
                          </ul>
                          <h3>Responsibilities of The Users</h3>
                          <p>Users are advised to call the bus operator to find out the exact boarding point, or any information which they may need for the purpose of boarding or travel in that trip.
                            <br /><br />At the time of boarding the bus, Users shall furnish a copy of the ticket.<br /><br />Users are required to reach the boarding place at least 30 minutes before the scheduled departure time.
                            <br /><br />All tickets issued shall be non-transferable.<br /><br />User needs to compulsorily obtain a bus ticket at the regular fare in case a child above the age of 5 years is accompanied by them, unless otherwise a particular bus operator specifies otherwise in their terms and conditions.</p>
                          <h3>Cancellation of Ticket</h3>
                          <p>Cancellation of tickets can be done either through the User's login in the {{ getCompanyInfo.name }}'s website or mobile application, or by calling on the customer care number;<br /><br />Any cancellation is subject to such cancellation charges as mentioned on the ticket.</p>
                          <p style="font-weight: bold;">EID NOTICE: EID TICKETS ARE NON REFUNDABLE AND CAN NOT BE CHANGED</p>
                          <h3>Payment Method</h3>
                          <p>Payment can be made via various channels, through Credit Card/ Debit Card/ Net Banking/ MFS. Total payment includes seat fare plus additional processing fee. The total sum will be displayed to you and that only will be deducted from your account. No additional fee will be charged later.</p>
                          <h3>Refund Policy</h3>
                          <p>We value your 'Savings' and so we have a Refund policy for proper compensation. In case the fault is of the operator or from our side, we refund the seat's price to our esteemed users.
                            The money will be refunded to you via cheque or deposited in your Bank account or MFS account. This price however does not include the Convenience fee. Refund will be made on tickets that are cancelled on time.
                            For any other reason we are not liable to refund.<br /><br />However, if refund request is complex which requires validation from bus operators and accuracy of complain, the refund can take 7 working days.</p>
                          <h3>Regarding Pricing Policy</h3>
                          <p>Total fare charged from user is inclusive of fare charged by bus operator plus convenience fee and Payment gateway charge/COD/WIC. The convenience fee does not include packaging fee, it only contains booking fee and servicing fee.
                            The convenience fee is 20Tk/seat +4% Payment gateway charges. Due to maintenance demand service charge might be higher than usual during Eid period.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .spinner.spinner-right {
        padding-right: 3.5rem !important;
    }
    .bg-green{
        background-color: #20b887 !important;
        color:white;
    }
</style>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "privacy-policy",
        computed:{
          ...mapGetters(['getCompanyInfo'])
        },
    };
</script>
